<template>
  <div class="examine">
    <div class="examineTop">
      <div class="top">
        <div class="examineTopNav">
          <!-- <div class="top"> -->
          <router-link
            to="/examine/application"
            active-class="active"
          >
            <!-- @click="routerClick(1)" -->
            <div class="text">
              我的申请
              <div class="bar"></div>
            </div>
          </router-link>
          <router-link
            to="/examine/myExamine"
            active-class="active"
          >
            <div class="text">
              我的审批
              <div class="bar"></div>
            </div>
          </router-link>
          <router-link
            to="/examine/record"
            active-class="active"
          >
            <div class="text">
              审批记录
              <div class="bar"></div>
            </div>
          </router-link>
        </div>
      </div>
      <div class="bottom">
        <div class="search_box">
          <el-input
            class="ipt"
            placeholder="关键词搜索，如:案件名称"
            prefix-icon="iconfont iconfangdajing"
          >
          </el-input>
          <div class="select_wrap">
            <div class="select_title">类型</div>
            <el-select
              class="my_select"
              placeholder="请选择"
              size="medium"
              v-model="state.examineType"
            >
              <el-option
                v-for="item in state.examineTypeList"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </div>
          <div class="select_wrap">
            <div class="select_title">状态</div>
            <el-select
              class="my_select"
              placeholder="请选择"
              size="medium"
              v-model="state.examineStatus"
            >
              <el-option
                v-for="item in state.examineStatusList"
                :key="item.label"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </div>
        </div>
        <el-button
          type="primary"
          v-if="btnShow"
          @click="handleOpenTypeDialog"
        >新建申请</el-button>
      </div>
    </div>
    <div v-loading="loading">
      <router-view
        @btnDisplay="btnDisplay"
        :examinList="state.examinList"
        @deletExamine="deletExamine"
        @judgeContent="judgeContent"
      ></router-view>
    </div>
    <!-- 分页组件 -->
    <div class="pagination_wrap">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="1"
        :page-sizes="PAGE_SIZES"
        :page-size="PAGE_SIZE"
        layout="total, sizes, prev, pager, next, jumper"
        :total="state.total"
      >
      </el-pagination>
    </div>
    <!-- 新建申请弹框 -->
    <MyDialog
      title="请选择申请类型"
      :isShow="isTypeShow"
      :closeDialog="handleCloseTypeDialog"
    >
      <div class="type_Form">
        <el-radio-group
          v-model="typeRadio"
          @change="changeTypeRadio"
        >
          <div style="margin-top: 20px">
            <el-radio :label="1">用印</el-radio>
            <el-radio :label="2">立案</el-radio>
          </div>
          <div style="margin: 20px 0">
            <el-radio :label="3">立项</el-radio>
            <el-radio :label="4">收款</el-radio>
          </div>
          <div style="margin: 20px 0">
            <el-radio :label="5">开票</el-radio>
          </div>
        </el-radio-group>
      </div>
    </MyDialog>
    <!-- 新建申请弹框 -->
    <MyDialog
      title="请填写基本信息"
      :isShow="isApplicationShow"
      :isDesignTitle="true"
    >
      <template #title>
        <div class="titleBox">
          <div
            class="back"
            @click="backType"
          >
            <div class="iconfont iconlfArrow"></div>
            <div class="text">返回</div>
          </div>
          <div class="title">
            <div class="titleText">请填写基本信息</div>
          </div>
          <div
            @click="handleCloseApplicationDialog"
            class="iconfont iconguanbi"
          ></div>
        </div>
      </template>
      <!-- <template> -->
      <add-examine-application
        :examineType="typeRadio"
        @addSubmit="addSubmit"
      > </add-examine-application>
      <!-- </template> -->
    </MyDialog>
  </div>
</template>

<script>
import MyDialog from "@/components/myDialog";
import { defineComponent, reactive, ref, onMounted } from "vue";
import { useConst } from "@/hooks/usePagination";
import { getExamineList } from "@/api/examine";
// import { result } from "../utils/examinedata";
import AddExamineApplication from "@/components/AddExamineApplication.vue";
export default defineComponent({
  name: "examine",
  components: {
    MyDialog,
    AddExamineApplication,
  },
  setup() {
    let loading = ref(false);
    let btnShow = ref(false);
    let isTypeShow = ref(false);
    let isApplicationShow = ref(false);
    let typeRadio = ref("");
    let state = reactive({
      queryParams: {
        // applyType: null, //	申请类型,10017;1 用印,2 立案,3 立项,4 收款,5 开票
        // keyword: "", //关键字
        // status: null, //字典: 10020 状态1 待审批,2 通过,3 不通过
        type: 1, //1-我的申请,2-我的审批,3-审批记录
        pageNo: 1,
        pageSize: 10,
      },
      examineType: null,
      examineTypeList: [
        { value: 1, label: "用印" },
        { value: 2, label: "立案" },
        { value: 3, label: "立项" },
        { value: 4, label: "收款" },
        { value: 5, label: "开票" },
      ],
      examineStatus: null,
      examineStatusList: [
        { value: 1, label: "待审批" },
        { value: 2, label: "通过" },
        { value: 3, label: "不通过" },
      ],
      customForm: {
        customerName: "",
        customerType: 1,
        birthday: "",
        customerRemark: "",
        tagList: [],
      },
      customerTagArr: [],
      customerTypeArr: [],
      rules: {
        customerType: [
          {
            required: true,
            message: "请选择客户类型",
            trigger: "blur",
          },
        ],
        customerName: [
          {
            required: true,
            message: "请输入姓名",
            trigger: "blur",
          },
        ],
      },
      examinList: [],
      total: 0,
    });
    const SizeChange = (val) => {
      console.log(val);
      state.queryParams.pageSize = val;
      getList();
    };
    const CurrentChange = (val) => {
      console.log(val);
      state.queryParams.pageNo = val;
      getList();
    };
    onMounted(() => {});
    // 获取审批列表
    const getList = async () => {
      console.log("state.queryParams", state.queryParams);
      loading.value = true;
      let res = await getExamineList(state.queryParams);
      // let res = result;
      loading.value = false;
      console.log("page", res);
      if (res.code == 200) {
        state.examinList = res.data.records;
        state.total = parseInt(res.data.total);
      }
    };
    //打开新增类型弹窗
    const handleOpenTypeDialog = () => {
      // isApplicationShow.value = true;
      isTypeShow.value = true;
    };
    const backType = () => {
      isApplicationShow.value = false;
      isTypeShow.value = true;
    };
    //关闭新增类型弹窗
    const handleCloseTypeDialog = () => {
      // isApplicationShow.value = false;
      isTypeShow.value = false;
    };
    //打开新增申请弹窗
    const handleOpenApplicationDialog = () => {
      isApplicationShow.value = true;
      // typeRadio.value = "";
      isTypeShow.value = false;
    };
    //关闭新增申请弹窗
    const handleCloseApplicationDialog = () => {
      isApplicationShow.value = false;
    };
    // 是否显示按纽
    const btnDisplay = (item) => {
      btnShow.value = item;
    };
    //选择申请类型
    const changeTypeRadio = () => {
      console.log("选择申请类型", typeRadio.value);
      handleOpenApplicationDialog();
    };
    //新增按纽提交成功
    const addSubmit = () => {
      isApplicationShow.value = false;
      getList();
    };
    //删除成功
    const deletExamine = () => {
      getList();
    };
    //判断列表类型
    const judgeContent = (value) => {
      console.log("judgeContent", value);
      state.queryParams.type =
        value == "application" ? 1 : value == "myExamine" ? 2 : 3;
      getList();
    };
    const { PAGE_SIZE, PAGE_SIZES, handleSizeChange, handleCurrentChange } =
      useConst(SizeChange, CurrentChange);

    return {
      btnShow, //新建按纽显示
      loading,
      isTypeShow, //新增类型弹窗显示
      isApplicationShow, //新增申请弹窗显示
      state,
      typeRadio, //申请类型单选框
      PAGE_SIZE,
      PAGE_SIZES,
      handleSizeChange,
      handleCurrentChange,
      btnDisplay,
      handleOpenTypeDialog,
      handleCloseTypeDialog,
      // handleOpenApplicationDialog,
      handleCloseApplicationDialog,
      changeTypeRadio,
      backType,
      addSubmit,
      deletExamine,
      judgeContent,
    };
  },
});
</script>

<style lang="scss" scoped>
@import "@/assets/style/comman.scss";
.examine {
  @include container;
  background: #fff;
  border-radius: 20px;
  .examineTop {
    .top {
      width: 100%;
      border-bottom: 1px solid #eee;
      // margin-bottom: 20px;
      padding: 20px 0;
      .examineTopNav {
        padding: 20px 30px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 248px;
        height: 100%;
        margin: 0 auto;
        a {
          text-decoration: none;
          font-size: 16px;
          color: #666;
          &:hover {
            color: $main-color;
          }
          .text {
            position: relative;
            .bar {
              display: none;
              @include underLine;
              left: 50%;
              transform: translateX(-50%);
            }
          }
        }
        .active {
          color: $main-color;
          .text {
            position: relative;
            .bar {
              display: block;
            }
          }
        }
      }
    }
    .bottom {
      height: 90px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 20px;
      border-bottom: 1px solid #edeff2;

      .search_box {
        flex: 1;
        display: flex;

        .ipt {
          width: 213px;

          ::v-deep .el-input__inner {
            border-radius: 20px;
          }
        }

        .select_wrap {
          height: 40px;
          border-radius: 20px;
          border: 1px solid #edeff2;
          margin-left: 10px;
          display: flex;
          align-items: center;

          .select_title {
            border-right: 1px solid #edeff2;
            height: 14px;
            width: 44px;
            text-align: center;
            line-height: 14px;
            font-size: 14px;
            color: #606266;
          }

          .my_select {
            flex: 1;

            ::v-deep .el-input__inner {
              border-radius: 0 20px 20px 0;
              border: none;
            }
          }
        }
      }
    }
  }
  .pagination_wrap {
    padding: 0 20px;
    display: flex;
    justify-content: flex-end;
    margin-top: 10px;
  }
}
.type_Form {
  text-align: center;
  .el-radio-group {
    margin: 20px auto;
  }
}
.titleBox {
  display: flex;
  align-items: center;
  .back {
    color: #555;
    display: flex;
    align-items: center;
    &:hover {
      cursor: pointer;
      color: $main-color;
    }
    .iconlfArrow {
      font-size: 20px;
    }
    .text {
      font-size: 14px;
      margin-left: 10px;
    }
  }
  .title {
    width: 85%;
    line-height: 60px;
    font-size: 16px;
    color: #555;
    text-align: center;
  }
  .iconfont .iconguanbi {
    font-size: 16px;
    color: #777;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 20px;
    &:hover {
      cursor: pointer;
      color: $main-color;
    }
  }
}
</style>
