<template>
  <div class="examine_form">
    <el-form
      :model="state.examineForm"
      :rules="state.rules"
      ref="examineForm"
      label-width="100px"
      class="demo-ruleForm"
    >
      <!-- 用印事项 用印-->
      <div
        class="form_box"
        v-if="examine_Type.examine_Type_Show_1"
      >
        <el-form-item
          label="用印事项"
          class="form_item_type"
          prop="itemType"
        >
          <el-select
            v-model="state.examineForm.sealExtra.itemType"
            size="medium"
          >
            <el-option
              v-for="item in state.sealExtra_itemTypeList"
              :key="item.key"
              :label="item.value"
              :value="item.key"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          prop="applyName"
          class="form_item_name"
        >
          <el-input
            v-model="state.examineForm.sealExtra.applyName"
            size="medium"
          ></el-input>
        </el-form-item>
      </div>
      <!-- 发票类型 开票-->
      <el-form-item
        label="发票类型"
        prop="type"
        v-if="examine_Type.examine_Type_Show_5"
      >
        <el-select
          v-model="state.examineForm.invoiceExtra.type"
          style="width: 100%;"
          placeholder="请选择"
          size="medium"
        >
          <el-option
            v-for="item in state.typeList"
            :key="item.key"
            :label="item.value"
            :value="item.key"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <!-- 抬头 开票-->
      <div
        class="form_box"
        v-if="examine_Type.examine_Type_Show_5"
      >
        <el-form-item
          label="抬头"
          class="form_item_type"
          prop="titleType"
        >
          <el-select
            v-model="state.examineForm.invoiceExtra.titleType"
            size="medium"
          >
            <el-option
              v-for="item in state.titleTypeList"
              :key="item.key"
              :label="item.value"
              :value="item.key"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          prop="titleContent"
          class="form_item_name"
        >
          <el-input
            v-model="state.examineForm.invoiceExtra.titleContent"
            size="medium"
          ></el-input>
        </el-form-item>
      </div>
      <!-- 关联 用印、开票、收款-->
      <div
        class="form_box"
        v-if="examine_Type.examine_Type_Show_1||examine_Type.examine_Type_Show_4||examine_Type.examine_Type_Show_5"
      >
        <el-form-item
          label="关联"
          class="form_item_type"
          prop="relType"
        >
          <el-select
            v-model="state.examineForm.relType"
            size="medium"
            @change="relTypeChange"
          >
            <el-option
              v-for="item in state.relTypeList"
              :key="item.key"
              :label="item.value"
              :value="item.key"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          prop="relId"
          class="form_item_name"
        >
          <el-autocomplete
            class="inline-input"
            v-model="state.relName"
            :fetch-suggestions="querySearch_relId"
            placeholder="请输入关联内容"
            @select="handleSelect_relId"
          ></el-autocomplete>
        </el-form-item>
        <!-- :trigger-on-focus="false" -->
      </div>
      <!-- 印章类型 用印-->
      <el-form-item
        label="印章类型"
        prop="sealType"
        v-if="examine_Type.examine_Type_Show_1"
      >
        <el-select
          v-model="state.examineForm.sealExtra.sealType"
          placeholder="请选择"
          size="medium"
        >
          <el-option
            v-for="item in state.sealExtra_sealTypeList"
            :key="item.key"
            :label="item.value"
            :value="item.key"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <!-- 用印份数 用印-->
      <el-form-item
        label="用印份数"
        prop="copyNum"
        v-if="examine_Type.examine_Type_Show_1"
      >
        <el-input
          v-model="state.examineForm.sealExtra.copyNum"
          oninput="value=value.replace(/[^\d]/g,'')"
        >
          <template #suffix>
            <i style="margin-right: 10px;">份</i></template>
        </el-input>
      </el-form-item>
      <!-- 项目 立项 reltype=2-->
      <div v-if="examine_Type.examine_Type_Show_3">
        <el-form-item
          label="项目"
          prop="relIdProject"
        >
          <el-autocomplete
            style="width: 100%;"
            class="inline-input"
            v-model="state.relName"
            :fetch-suggestions="querySearch_relId"
            placeholder="请输入关联内容"
            @select="handleSelect_project"
          ></el-autocomplete>
          <!-- :trigger-on-focus="false" -->
        </el-form-item>
        <div class="contentBox">
          <div class="contentItem">
            <div class="keyTitle">项目类型</div>
            <div class="valueTitle">{{state.projectInfo.type}}</div>
          </div>
          <div class="contentItem">
            <div class="keyTitle">委托时间</div>
            <div class="valueTitle">{{ state.projectInfo.createDate}}</div>
          </div>
          <div
            class="contentItem"
            v-for="item in state.projectInfo.relEntrustVOList"
            :key="item.entrustCustomerId"
          >
            <div class="keyTitle">委托方</div>
            <div class="valueTitle">{{item.entrustCustomerName}}</div>
          </div>
        </div>
      </div>
      <!-- 收入 收款、开票-->
      <el-form-item
        label="收入"
        prop="chargeId"
        v-if="examine_Type.examine_Type_Show_4||examine_Type.examine_Type_Show_5"
      >
        <el-select
          v-model="state.examineForm.receiveExtra.chargeId"
          style="width: 100%;"
          placeholder="请选择"
          size="medium"
        >
          <el-option
            v-for="item in state.chargeIdList"
            :key="item.key"
            :label="item.value"
            :value="item.key"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <!-- 案件 立案 -->
      <div v-if="examine_Type.examine_Type_Show_2">
        <el-form-item
          label="案件"
          prop="relIdCase"
        >
          <el-autocomplete
            style="width: 100%;"
            class="inline-input"
            v-model="state.relName"
            :fetch-suggestions="querySearch_relId"
            placeholder="请输入关联内容"
            @select="handleSelect_case"
          ></el-autocomplete>
          <!-- :trigger-on-focus="false" -->
        </el-form-item>
        <div class="contentBox">
          <div class="contentItem">
            <div class="keyTitle">案件编号</div>
            <div class="valueTitle">{{state.caseInfo.caseNo}}</div>
          </div>
          <div class="contentItem">
            <div class="keyTitle">委托时间</div>
            <div class="valueTitle">{{state.caseInfo.entrustDate}}</div>
          </div>
          <div class="contentItem">
            <div class="keyTitle">案件发生时间</div>
            <div class="valueTitle">{{state.caseInfo.caseNo}}</div>
          </div>
          <div class="tableBoxCon">
            <div class="tableTitle">我方</div>
            <div class="tableBox">
              <div
                class="tableItem"
                v-for="item in state.caseInfo.ourLitigantList"
                :key="item.holderId"
              >
                <div class="tableItemName">{{item.holderName}}</div>
                <div class="tableItemName">{{item.holderTypeName}}</div>
                <div class="tableItemName">{{item.partyTypeName}}</div>
              </div>
            </div>
          </div>
          <div class="tableBoxCon">
            <div class="tableTitle">对方</div>
            <div class="tableBox">
              <div
                class="tableItem"
                v-for="item in state.caseInfo.oppositeLitigantList"
                :key="item.holderId"
              >
                <div class="tableItemName">{{item.holderName}}</div>
                <div class="tableItemName">{{item.holderTypeName}}</div>
                <div class="tableItemName">{{item.partyTypeName}}</div>
              </div>
            </div>
          </div>
          <div class="contentItem">
            <div class="keyTitle">案件标的</div>
            <div class="valueTitle">{{state.caseInfo.subject}}</div>
          </div>
          <div class="contentItem">
            <div class="keyTitle">标的金额</div>
            <div class="valueTitle">{{state.caseInfo.subjectAmount}}</div>
          </div>
          <div class="contentItem">
            <div class="keyTitle">事实与理由</div>
            <div class="valueTitle">{{state.caseInfo.factualReason}}</div>
          </div>
        </div>
      </div>
      <!-- 审批人 -->
      <el-form-item
        label="审批人"
        prop="reviewUserId"
      >
        <el-select
          v-model="state.examineForm.reviewUserId"
          style="width: 100%;"
          placeholder="请选择"
          size="medium"
        >
          <el-option
            v-for="item in state.reviewUserList"
            :key="item.key"
            :label="item.value"
            :value="item.key"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <!-- 附件 -->
      <el-form-item
        label="附件"
        prop="fileIdList"
        v-if="examine_Type.examine_Type_Show_1||examine_Type.examine_Type_Show_4||examine_Type.examine_Type_Show_5"
      >
        <!-- <el-upload
          ref='myUpload'
          class="uploadComp"
          action="#"
          multiple
          :limit='5'
          :auto-upload='false'
          :on-change="handleChangeUpload"
          :file-list="state.examineForm.fileIdList"
          :http-request='myUploadSubmit'
          :on-exceed='onExceed'
          accept=".png,.jpg,.jpeg,.doc,.docx,.pdf,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        > -->
        <el-upload
          ref='myUpload'
          class="uploadComp"
          action="#"
          :limit='5'
          :http-request='myUploadSubmit'
          accept=".png,.jpg,.jpeg,.doc,.docx,.pdf,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        >
          <el-button
            round
            size="medium"
            type="primary"
          ><i class="el-icon-plus el-icon--left"></i>附件</el-button>
          <template #tip>
            <div class="el-upload__tip">
              可上传<span style="color:red"> 5个附件</span><br>每个附件大小不得超过8M，支持格式：“jpg”，“png”，“doc”，“pdf”，“xls”
            </div>
          </template>
        </el-upload>
      </el-form-item>
      <!-- 备注 -->
      <el-form-item label="备注">
        <el-input
          type="textarea"
          v-model="state.examineForm.applyRemark"
          size="medium"
        ></el-input>
      </el-form-item>
      <div class="btn_wrap">
        <el-button
          class="btn"
          type="primary"
          @click="subAddExamine"
        >提交申请</el-button>
      </div>
    </el-form>
  </div>
</template>
<script>
import { defineComponent, reactive, ref, onMounted, toRef } from "vue";
import {
  getCaseList,
  getProjectList,
  getReviewUserList,
  getCaseRealCharge,
  getProjectRealCharge,
  addExamine,
  uploadFile,
  getProjectInfo,
} from "@/api/examine";
import { getCaseInfo } from "@/api";
import { useStore } from "vuex";
export default defineComponent({
  name: "AddExamineApplication",
  emits: ["addSubmit"],
  setup(props, context) {
    const examine_Type = reactive({
      //用印
      examine_Type_Show_1: false,
      //立案
      examine_Type_Show_2: false,
      //立项
      examine_Type_Show_3: false,
      //收款
      examine_Type_Show_4: false,
      //开票
      examine_Type_Show_5: false,
    });
    //表单ref对象
    const examineForm = ref();
    //vueX对象
    const store = useStore();
    const state = reactive({
      examineForm: {
        applyRemark: "111111", //	申请备注
        applyType: 1, //	申请类型,10017;1 用印,2 立案,3 立项,4 收款,5 开票
        fileIdList: [], //	文件id列表
        invoiceExtra: {
          //	开票审批特殊额外信息
          bankAccount: "1", //开户行帐号
          bankName: "1", //开户行
          chargeId: 1, //	单据id
          enterpriseAddr: "1111", //单位地址
          enterpriseTel: "", //	单位电话
          socialCreditCode: "11111111", //	税号,社会信用编码
          titleContent: "111111", //	抬头内容
          titleType: "", //抬头类型,字典10023;1 个人 2 单位
          type: "", //发票类型
        },
        receiveExtra: {
          //收款特殊额外信息
          chargeId: "", //收入单据id
        },
        relId: "", //关联对象
        relType: "", //关联类型,字典10002
        reviewUserId: 1111, //审批人用户id
        sealExtra: {
          //	用印审批特殊额外信息
          applyName: "", //申请名称
          copyNum: 0, //份数
          itemType: "", //用印事项类型,字典10021;1 委托合同,2 出庭证,3 会见证,4 律师函,5 立案函,6 调查令,7 法律意见书,8 专用介绍信,9 律师询证函,10 其他
          sealType: "", //印章类型,字典10022;1 公章,2 合同章,3 财务章,4 法人章
        },
      },
      sealExtra_itemTypeList: [], //用印事项类型
      sealExtra_sealTypeList: [], //印章类型
      reviewUserList: [], //审批人
      //关联类型
      relTypeList: [
        {
          expandInfo: null,
          key: 2,
          keyL: null,
          value: "案件",
        },
        {
          expandInfo: null,
          key: 3,
          keyL: null,
          value: "项目",
        },
      ],
      relIdList: [], //关联对象
      relIdList_case: [], //案件关联对象
      relIdList_project: [], //项目关联对象
      titleTypeList: [], //抬头类型
      typeList: [], //发票类型
      chargeIdList: [], //收入
      chargeIdList_case: [], //案件关联收入
      chargeIdList_project: [], //项目关联收入
      relName: "", //关联对象名
      caseInfo: {}, //案件详情
      projectInfo: {}, //项目详情
      rules: {
        itemType: [
          {
            required: true,
            message: "请选择用印事项类型",
            trigger: "blur",
          },
        ],
        applyName: [
          {
            required: true,
            message: "请输入申请名称",
            trigger: "blur",
          },
        ],
        sealType: [
          {
            required: true,
            message: "请输入印章类型",
            trigger: "blur",
          },
        ],
        copyNum: [
          {
            required: true,
            message: "请输入用印份数",
            trigger: "blur",
          },
        ],
        reviewUserId: [
          {
            required: true,
            message: "请输入审批人",
            trigger: "blur",
          },
        ],
        fileIdList: [
          {
            required: true,
            message: "请选择附件",
            trigger: "blur",
          },
        ],
        relIdCase: [
          {
            required: true,
            message: "请输入案件",
            trigger: "blur",
          },
        ],
        type: [
          {
            required: true,
            message: "请输入发票类型",
            trigger: "blur",
          },
        ],
        titleType: [
          {
            required: true,
            message: "请输入抬头类型",
            trigger: "blur",
          },
        ],
        titleContent: [
          {
            required: true,
            message: "请输入抬头",
            trigger: "blur",
          },
        ],
        relType: [
          {
            required: true,
            message: "请输入申请名称",
            trigger: "blur",
          },
        ],
        relId: [
          {
            required: true,
            message: "请输入申请名称",
            trigger: "blur",
          },
        ],
        chargeId: [
          {
            required: true,
            message: "请输入申请名称",
            trigger: "blur",
          },
        ],
        relIdProject: [
          {
            required: true,
            message: "请输入申请名称",
            trigger: "blur",
          },
        ],
      },
    });
    //初始化下拉框数据
    const initOptionList = async () => {
      examine_Type.examine_Type_Show_1 &&
        //用印事项类型
        ((state.sealExtra_itemTypeList = await store.dispatch(
          "getDataDictionary",
          10021
        )),
        //印章类型
        (state.sealExtra_sealTypeList = await store.dispatch(
          "getDataDictionary",
          10022
        )));
      examine_Type.examine_Type_Show_5 &&
        //抬头类型
        ((state.titleTypeList = await store.dispatch(
          "getDataDictionary",
          10023
        )),
        //发票类型
        (state.typeList = await store.dispatch("getDataDictionary", 10037)));
      //审批人
      let res_reviewUser = await getReviewUserList({
        reviewType: props.examineType,
      });
      console.log("res_reviewUser", res_reviewUser);
      if (res_reviewUser.code == 200) {
        state.reviewUserList = res_reviewUser.data.userList;
      }
    };
    //关联类型选择事件
    const relTypeChange = (val) => {
      console.log("relTypeChange", val);
      state.relIdList =
        val == 2 ? state.relIdList_case : state.relIdList_project;
      console.log(
        "🚀 ~ file: AddExamineApplication.vue ~ line 587 ~ relTypeChange ~ state.relIdList",
        state.relIdList
      );
    };
    //加载模糊查询数据列表
    const loadAllQuery = async () => {
      let param = { pageNo: 1, pageSize: 10000 };
      let res_case = await getCaseList(param);
      if (res_case.code == 200) {
        state.relIdList_case = res_case.data.records.map((item) => {
          return {
            key: item.caseId,
            value: item.caseName,
          };
        });
        examine_Type.examine_Type_Show_2 &&
          (state.relIdList = state.relIdList_case);
      }
      let res_project = await getProjectList(param);
      if (res_project.code == 200) {
        state.relIdList_project = res_project.data.records.map((item) => {
          return {
            key: item.projectId,
            value: item.projectName,
          };
        });
        examine_Type.examine_Type_Show_3 &&
          (state.relIdList = state.relIdList_project);
      }
    };
    //模糊查询过滤方法
    const createFilter = (queryString) => {
      return (restaurant) => {
        return (
          restaurant.value.toLowerCase().indexOf(queryString.toLowerCase()) ===
          0
        );
      };
    };
    //关联模糊查询事件
    const querySearch_relId = (queryString, cb) => {
      var results = queryString
        ? state.relIdList.filter(createFilter(queryString))
        : state.relIdList;
      cb(results);
    };
    //关联模糊查询选择事件
    const handleSelect_relId = async (selectItem) => {
      console.log(selectItem);
      state.examineForm.relId = selectItem.key;
      state.relName = selectItem.value;
      //收入下拉列表数据
      let param = {
        pageNo: 1,
        pageSize: 1000000,
        realId: state.examineForm.relId,
      };
      if (
        //收款或开票弹窗
        examine_Type.examine_Type_Show_4 ||
        examine_Type.examine_Type_Show_5
      ) {
        let res_charge =
          state.examineForm.relType == 2 //关联为案件或是项目
            ? await getCaseRealCharge(param)
            : await getProjectRealCharge(param);
        console.log("res_charge", res_charge);
        if (res_charge.code == 200) {
          state.chargeIdList = [
            ...res_charge.data.payVoList.records.map((item) => {
              return {
                key: item.chargeId,
                value: item.chargeName,
              };
            }),
            ...res_charge.data.receivedVoList.records.map((item) => {
              return {
                key: item.chargeId,
                value: item.chargeName,
              };
            }),
          ];
        }
      }
    };
    //案件关联模糊查询选择事件
    const handleSelect_case = async (selectItem) => {
      console.log(selectItem);
      let res = await getCaseInfo({ caseId: selectItem.key });
      console.log("getcaselist", res);
      if (res.code == 200) {
        state.caseInfo = res.data;
      }
      state.examineForm.relId = selectItem.key;
      state.relName = selectItem.value;
    };
    //项目关联模糊查询选择事件
    const handleSelect_project = async (selectItem) => {
      console.log(selectItem);
      let res = await getProjectInfo({ projectId: selectItem.key });
      console.log("getcaselist", res);
      if (res.code == 200) {
        state.projectInfo = res.data;
      }
      state.examineForm.relId = selectItem.key;
      state.relName = selectItem.value;
    };
    //上传功能相关方法
    const handleFliePreview = (file) => {
      console.log(file);
      const isIMAGE = file.type === "image/jpeg" || "image/gif" || "image/png";
      const isLt5M = file.size / 1024 / 1024 < 8;
      if (!isIMAGE) {
        this.$message.error(
          "上传文件只能是“jpg”，“png”，“doc”，“pdf”，“xls”格式!"
        );
      }
      if (!isLt5M) {
        this.$message.error("上传文件大小不能超过 8MB!");
      }
    };
    //提交新增审批申请
    const subAddExamine = async () => {
      state.examineForm.applyType = props.examineType;
      let res = await addExamine(state.examineForm);
      if (res.code == 200) {
        console.log(res, "addSubmit");
        context.emit("addSubmit");
      }
      // examineForm.value.validate(async (valid) => {
      //   console.log(valid);
      //   let res = await addExamine(state.examineForm);
      //   if (res.code == 200);
      //   console.log(res);
      // });
    };
    const handleChangeUpload = (file, fileList) => {
      console.log("file:", file);
      console.log("fileList:", fileList);
      let list = [];
      fileList.forEach((item) => {
        list.push(item.uid + "");
      });
      state.examineForm.fileIdList = list;
      // handleFliePreview(file);
    };
    const onExceed = () => {
      console.log("onExceed");
      this.$message.error(
        "可上传 5个附件,上传文件只能是“jpg”，“png”，“doc”，“pdf”，“xls”格式!"
      );
    };
    const myUploadSubmit = async (item) => {
      console.log("myUploadSubmit:", item);
      let formData = new FormData();
      formData.append("file", item.file);
      formData.append("filepath", "user-atvar");
      let res = await uploadFile(formData);
      console.log(res);
      if (res.code == 200) {
        // ossUrl.value = res.data.ossUrl;
        state.examineForm.fileIdList.push(res.data.fileId);
      }
    };
    onMounted(() => {
      props.examineType &&
        (examine_Type["examine_Type_Show_" + props.examineType] = true);
      //用印类型时，关联非必填项
      examine_Type.examine_Type_Show_1 &&
        (delete state.rules.relType, delete state.rules.relId);
      initOptionList();
      loadAllQuery();
    });
    return {
      state,
      examine_Type,
      examineForm,
      subAddExamine,
      querySearch_relId,
      handleSelect_relId,
      handleChangeUpload,
      onExceed,
      myUploadSubmit,
      handleSelect_case,
      handleSelect_project,
      relTypeChange,
    };
  },
  props: {
    examineType: {
      type: Number,
      require: true,
      default: 1,
    },
  },
});
</script>
<style lang="scss" scoped>
@import "@/assets/style/comman.scss";
.examine_form {
  padding: 20px 30px;
  .form_box {
    display: flex;
    .form_item_type {
      flex: 0 0 260px;
    }
    .form_item_name {
      flex: 1;
      ::v-deep .el-form-item__content {
        margin-left: 0 !important;
      }
    }
  }
  .btn_wrap {
    display: flex;
    justify-content: center;
  }
  .contentBox {
    padding-left: 10px;
    .contentItem {
      margin-bottom: 30px;
      display: flex;
      align-items: center;
      .keyTitle {
        min-width: 56px;
        width: 88px;
        text-align: right;
        margin-right: 20px;
      }
      .textRight {
        text-align: right;
      }
      .flex1 {
        flex: 1;
        line-height: 20px;
      }
      .keyTitle,
      .valueTitle {
        font-size: 14px;
        color: #777777;
      }
      .keyIcon {
        font-size: 40px;
        color: $main-color;
        margin-right: 30px;
      }
    }
    .tableBoxCon {
      width: 90%;
      display: flex;
      margin: auto;
      margin-bottom: 20px;
      .tableTitle {
        font-size: 16px;
        color: #666;
        width: 60px;
      }
      .tableBox {
        width: 100%;
        .tableItem {
          display: flex;
          width: 100%;
          margin-bottom: 20px;
          &:last-child {
            margin-bottom: 0;
          }
          .tableItemName {
            // width:calc( (100% - 60px) / 2 );
            width: 165px;
            font-size: 14px;
            color: #777;
            &:nth-child(2) {
              width: 100px;
            }
            &:nth-child(3) {
              width: 150px;
            }
          }
        }
      }
    }
  }
}
</style>
